





































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as appRegion from "@/libs/app_region";
@Component
export default class Merchants extends Vue {
  pagerHeader: any = {
    title: "商家营业统计",
    desc: "代理门店营业统计",
    breadcrumb: ["商家营业统计"]
  };
  loading: boolean = false;
  filter: any = {
    startStatisticsDate: null, //统计开始时间
    endStatisticsDate: null, //统计结束时间
    agentId: "", //代理ID
    status: null, //店铺状态  number 0为营业中，-1已关闭
    startDate: null,
    endDate: null,
    provinceCode: "", //省
    cityCode: "", //市
    areaCode: "", //区
    categoryId: "",
    isActive: null,
    keywords: "",
    page: 1,
    size: 10,
    sortField: null,
    sortDirection: null
  };
  tableData: any = {
    //显示到列表中
    items: [],
    totalCount: 0
  };
  dateRange: any[] = []; //开店时间改变
  dateRangeTong: any[] = []; //统计时间改变
  provinceOptions: any[] = []; //省
  cityOptions: any[] = []; //市
  countyOptions: any[] = []; //区
  agentSearchLoading: boolean = false; //搜索代理 出现loading
  agentItems: any[] = []; //代理数组
  value: any = "1";
  activeName = "first";//选择卡片或图表
  percentage=''
  // 监听选择省、市
  @Watch("filter.provinceCode")
  provinceCodeChange(provinceCode: string) {
    this.filter.cityCode = "";
    this.filter.areaCode = "";
    this.cityOptions = appRegion.getCitys(provinceCode);
    this.countyOptions = [];
  }
  @Watch("filter.cityCode")
  cityCodeChange(cityCode: string) {
    this.filter.areaCode = "";
    this.countyOptions = appRegion.getCountys(cityCode);
  }
  @Watch("value")
  valueChange(value: string) {
    if (value == "1") {
      //近7天
      this.filter.startStatisticsDate = new Date(
        // @ts-ignore
        new Date() - 7 * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 00:00:00");
      this.filter.endStatisticsDate = new Date().Format("yyyy-MM-dd 23:59:59");
    } else if (value == "2") {
      //近30天
      this.filter.startStatisticsDate = new Date(
        // @ts-ignore
        new Date() - 30 * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 00:00:00");
      this.filter.endStatisticsDate = new Date().Format("yyyy-MM-dd 23:59:59");
    } else if (value == "3") {
      this.filter.startStatisticsDate = new Date(
        // @ts-ignore
        new Date() - 90 * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 00:00:00");
      this.filter.endStatisticsDate = new Date().Format("yyyy-MM-dd 23:59:59");
    } else {
    }
  }
  async created() {
    this.filter.startStatisticsDate = new Date(
      // @ts-ignore
      new Date() - 7 * 24 * 3600 * 1000
    ).Format("yyyy-MM-dd 00:00:00");
    this.filter.endStatisticsDate = new Date().Format("yyyy-MM-dd 23:59:59");
    this.getData();
    this.provinceOptions = appRegion.getProvinces();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.post(
        "/api/services/app/Merchant/QueryTurnoverStatistics",
        {
          ...this.filter
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
        this.percentage=this.tableData.items[0].turnover+100
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  //开店日期改变
  timeVoi(value: Date[]) {
    if (value != null && value.length == 2) {
      this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
      if (
        value[1].Format("hh") == "00" &&
        value[1].Format("mm") == "00" &&
        value[1].Format("ss") == "00"
      ) {
        this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59"))
        ];
      } else {
        this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss"))
        ];
      }
      this.getData();
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.getData();
    }
  }
  //统计日期改变
  timeVoiTong(value: Date[]) {
    if (value != null && value.length == 2) {
      this.filter.startStatisticsDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
      if (
        value[1].Format("hh") == "00" &&
        value[1].Format("mm") == "00" &&
        value[1].Format("ss") == "00"
      ) {
        this.filter.endStatisticsDate = value[1].Format("yyyy-MM-dd 23:59:59");
        this.dateRangeTong = [
          (this.filter.startStatisticsDate = value[0].Format(
            "yyyy-MM-dd hh:mm:ss"
          )),
          (this.filter.endStatisticsDate = value[1].Format(
            "yyyy-MM-dd 23:59:59"
          ))
        ];
      } else {
        this.filter.endStatisticsDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
        this.dateRangeTong = [
          (this.filter.startStatisticsDate = value[0].Format(
            "yyyy-MM-dd hh:mm:ss"
          )),
          (this.filter.endStatisticsDate = value[1].Format(
            "yyyy-MM-dd hh:mm:ss"
          ))
        ];
      }
      this.getData();
    } else {
      this.value = "1";
      this.getData();
    }
  }
  async renderAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  //点击搜索
  search() {
    this.filter.page = 1;
    this.getData();
  }
  async sortChange(e: any) {
    //排序
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
  // 统计时间改变
  changeValue() {
    this.getData();
  }
  handleClick(){

  }
}
